import React from 'react'
import logoImg from '../img/PROFILE_SKILLUP_LOGO-02.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Cards1({ header, h1Span, h1 }) {
    return (
        <div className='wow fadeIn mb-12 mx-6 border-black max-w-[550px] flex flex-wrap justify-center' data-aos="fade-up" data-aos-duration="900" >
            <div className='relative border-[1px] border-solid border-[#dedfe2] mr-[2px] pb-[5%] rounded-[15px] shadow-[10px_5px_5px] shadow-gray-300 transition duration-[400s] hover:border-none hover:opacity-100 hover:shadow-[10px_5px_5px] hover:shadow-[#C0C0C0] ' >
                <div className='pl-[30px] pt-[30px] pr-[27px] pb-[24px]' >
                    <ul className='flex'>
                        <li className='max-w-[51px] mr-[15px] float-left'>
                            <LazyLoadImage src={logoImg} alt="Logo" className="border border-solid border-[#002147] rounded-full" />
                        </li>
                        <li>
                            <p className='text-[18px]'>{header}</p>
                        </li>
                    </ul>
                    <div>
                        <h1 data-aos="fade-right" data-aos-duration="900" className='m-0 text-[#002147] leading-10 tracking-[0.15px] border-b border-b-solid border-b-pink text-justify border-t-[1px] border-t-blue font-sans text-[1.2rem] mt-[20px] pt-[15px] mb-[19px] pb-4 visible font-normal'>
                            <span className='text-2xl'>{h1Span}</span>
                            {h1}
                        </h1>
                    </div>
                    <ul className='flex'>
                        <li className='max-w-[51px] mr-[15px] float-left'>
                            <LazyLoadImage src={logoImg} alt="Logo" className="border border-solid border-[#002147] rounded-full" />
                        </li>
                        <li>
                            <p className='text-[18px] text-left'>{header}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
