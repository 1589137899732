import React from 'react'
import { Link } from 'react-router-dom'

export default function HeaderTop() {

    return (
        <div className='pt-7 pb-10 bg-[#002147]'>
            <div className='container sm:flex mx-auto justify-between px-[15px]'>
                <div className='flex'>
                    <div className='mr-35 relative pr-30'>
                        <p className='inline-block m-0 text-[#fff] text-[13px] sm:text-[14px]'>
                            <i className="fas fa-phone text-[#fff] mr-[5px] fa-rotate-270"></i>
                            7208581498
                        </p>
                    </div>
                </div>
                <div className='text-center sm:text-right'>
                    <ul className='m-0 p-0 '>
                        <li className='inline-block leading-normal'>
                            <Link to='/' className='before:absolute before:content-[""] before:h-[0px] before:w-[0px] before:bg-[#fff] before:left-0 before:top-[2px]
                            text-[14px] text-[#fff] ml-[19px] pl-[19px] relative'
                            >
                                <span className="ti ti-facebook text-[#fff] "></span>
                            </Link>
                        </li>
                        <li className='inline-block leading-normal'>
                            <Link to='/' className='before:absolute before:content-[""] before:h-[12px] before:w-[1px] before:bg-[#fff] before:left-0 before:top-[2px]
                            text-[14px] text-[#fff] ml-[19px] pl-[19px] relative'
                            >
                                <span className="ti ti-google text-[#fff]"></span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
