import placement from '../img/modules/placement.jpg'
import cloudcomputing from '../img/modules/cloudcomputing.jpg'
import testing from '../img/modules/testing.jpg'

const FundamentalCoursesData2 = [
    {
        img: placement,
        name: 'Placement Modules',
        coursesHeading: 'Placement Module',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#3478f6'
    },
    {
        img: cloudcomputing,
        name: 'Infrastructure Modules',
        coursesHeading: 'Infrastructure Modules',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#f20f10'
    },
    {
        img: testing,
        name: 'Testing Modules',
        coursesHeading: 'Testing Module',
        core: 'Testing Module',
        courseDis: 'Excel at doing what your passion is and only focus on perfecting it.',
        bgColor: '#f20f10'
    },
]

export default FundamentalCoursesData2