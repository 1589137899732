import React from "react";
import Button2 from "./Button2";

export default function SlickSlider2({data}) {
    return (
        <div className="relative slider-area">
            {data.map((slide, index) => (
                <div key={index} style={{ width: '100%', height: '450px' }}>
                    <div
                        style={{
                            backgroundImage: `linear-gradient(
                                    to right,
                                    rgba(255, 255, 255, 0.5),
                                    rgba(255, 255, 255, 0.1)
                                  ), url(${slide.url})`,
                            width: '100%',
                            height: '450px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundSize: "cover",
                            backgroundPosition: 'center',
                            overflowX: 'hidden',
                        }}
                    >
                        <div className="container mx-auto pt-[49px] pb-[120px] md:pt-[62px] md:pb-[120px] lg:pt-[120px] lg:pb-[129px]">
                            <h1 data-aos="fade-up" data-aos-duration="800" className="custom-fade-up text-[34px] leading-[43px] md:text-[57px] md:leading-[85px]">
                                <span>
                                    <span className="text-pink font-bold">Skill</span>
                                    <span className="text-blue font-bold">Up</span>
                                    <span className="text-pink font-bold"> IT</span>
                                    <span className="text-blue font-bold"> Academy</span>
                                </span>
                            </h1>
                            <h2
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-duration="800"
                                className="custom-fade-up text-black text-[35px] mb-4 leading-tight"
                            >
                                {slide.data}
                            </h2>
                            <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="800" className="custom-fade-up">
                                <Button2 text="Apply Now" />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
