import React from 'react'

export default function QuizScore({score}) {
    return (
        <div className='w-full shadow-[0px_3px_12px] shadow-gray-300 rounded p-2 text-3xl font-medium mb-2'>
            <span className='text-base text-blue'>
                <i className="fa-solid fa-star fa-lg text-pink mr-2"></i>
                <h2 className='inline-block font-medium '>Score:</h2>
            </span>
            <ul className='pl-2 text-blue m-0'>
                {score.map((item, id) => {
                    return (
                        <li className='p-0' key={id}>
                            <span className='text-sm text-blue'>
                                <div className='w-[15px] h-[15px] inline-block' style={{ backgroundColor: `${item.bgColor}` }} ></div>
                                <h5 className='inline-block ml-2 font-medium'>{item.text}: {item.value}</h5>
                            </span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
