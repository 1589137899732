import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

export function ModalProvider({ children }) {
    const [showModal, setShowModal] = useState(false)

    const toggle = () => {
        setShowModal(true)
    }
    
    const close = () => {
        setShowModal(false);
    }
    return (
        <ModalContext.Provider
            value={{ showModal, toggle, close }}
        >
            {children}
        </ModalContext.Provider>
    )
}
