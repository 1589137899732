import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button2 from "./Button2";

const PreviousBtn = ({ className, onClick, handleArrowClick }) => (
    <div className={className} onClick={() => { onClick(); handleArrowClick(); }}>
        <ArrowBackIosNewIcon  />
    </div>
);

const NextBtn = ({ className, onClick, handleArrowClick }) => (
    <div className={className} onClick={() => { onClick(); handleArrowClick(); }}>
        <ArrowForwardIosIcon />
    </div>
);

export default function SlickSlider({data}) {
    const [key, setKey] = useState(Date.now());

    const handleArrowClick = () => {
        setKey(Date.now());
    };

    const settings = {
        autoplay: true,
        autoplaySpeed: 2800,
        dots: false,
        fade: true,
        arrows: true,
        prevArrow: <PreviousBtn handleArrowClick={handleArrowClick} />,
        nextArrow: <NextBtn handleArrowClick={handleArrowClick} />,
        beforeChange: handleArrowClick,
        responsive: [
            { breakpoint: 767, settings: { arrows: false } },
        ],
    };

    return (
        <div className="relative slider-area slider-active">
            <Slider {...settings}>
                {data.map((slide, index) => (
                    <div key={index} style={{ border: '1px solid #000', width: '100%', height: '480px' }}>
                        <div
                            style={{
                                backgroundImage: `linear-gradient(
                                    to right,
                                    rgba(255, 255, 255, 0.5),
                                    rgba(255, 255, 255, 0.1)
                                  ), url(${slide.url})`,
                                width: '100%',
                                height: '480px',
                                display: 'flex',
                                alignItems: 'center',
                                backgroundSize: "cover",
                                backgroundPosition: 'center',
                                overflowX: 'hidden',
                            }}
                        >
                            <div className="container mx-auto pt-[49px] pb-[120px] md:pt-[62px] md:pb-[120px] lg:pt-[120px] lg:pb-[129px]">
                                <h1 key={`${key}-h1`} data-aos="fade-up" data-aos-duration="800" className="custom-fade-up text-[34px] leading-[43px] md:text-[57px] md:leading-[85px]">
                                    <span>
                                        <span className="text-pink font-bold">Skill</span>
                                        <span className="text-blue font-bold">Up</span>
                                        <span className="text-pink font-bold"> IT</span>
                                        <span className="text-blue font-bold"> Academy</span>
                                    </span>
                                </h1>
                                <h2
                                    key={`${key}-h2`}
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                    data-aos-duration="800"
                                    className="custom-fade-up text-black text-[35px] mb-4 leading-tight"
                                >
                                    {slide.Title}
                                </h2>
                                <div key={`${key}-button`} data-aos="fade-up" data-aos-delay="400" data-aos-duration="800" className="custom-fade-up">
                                    <Button2 text="enquire now" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
