import React from 'react'
import badge from "../img/badge.png"
import certificate from "../img/certificate.png"
import star from "../img/star.png"
import { Link } from 'react-router-dom'
import logoImg from '../img/PROFILE_SKILLUP_LOGO-02.jpg'
import Cards3Btn from './Cards3Btn'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Cards2({ img,name, coursesHeading, core, courseDis, bgColor }) {
    return (
        <div data-aos="fade-up" data-aos-duration="900" className='A group relative w-full mx-4 mb-[4%] md:w-[350px] sm:max-md:h-[600px] lg:w-[290px] xl:w-[370px] xl:mx-[15px] border-[1px] border-solid border-[#dedfe2]  pb-[1%] rounded-[15px] shadow-[10px_5px_5px] shadow-gray-300 transition duration-500'>
            <div className='h-[230px]'>
                <Link>
                    <LazyLoadImage src={img} alt='fundamentalImg' className='relative h-full w-full rounded-tl-[15px] rounded-tr-[15px]' />
                </Link>
                <div className='top-[10px] right-auto absolute left-[25px]'>
                    <span className='relative bg-[#fed619] h-[35px] leading-9 my-0 mx-[5px] text-center w-[35px] bg-60 float-left bg-center bg-no-repeat rounded-full'>
                        <img src={badge} alt='' className="absolute inset-0 m-auto bg-no-repeat bg-center bg-[15px/23px] w-[15px] h-[23px]" />
                    </span>
                    <span className='relative bg-[#fed619] h-[35px] leading-9 my-0 mx-[5px] text-center w-[35px] bg-60 float-left bg-center bg-no-repeat rounded-full'>
                        <img src={certificate} alt='' className="absolute inset-0 m-auto bg-no-repeat bg-center bg-[15px/23px] w-[15px] h-[23px]" />
                    </span>
                </div>
            </div>
            <div>
                <div className='pl-[30px] pt-[30px] pr-[27px] pb-[24px]'>
                    <div className='mb-[3%] text-left'>
                        <span className='leading-none text-xs font-roboto text-[#002147] uppercase font-medium bg-[#fdc800] py-[9px] px-[15px] rounded-[3px] inline-block transition duration-300'>
                            <img src={star} alt='star' className='bg-center inline-block w-[10px] h-[11px] my-auto mr-[4px] bg-no-repeat align-baseline' />
                            Featured
                        </span>
                    </div>
                    <p className='text-left'>Any Stream</p>
                    <div>
                        <h1 className='text-[22px] leading-none m-0 text-[#002147] mt-[26px] border-b border-solid border-b-[#f0f0f0] pb-[15px] mb-[19px] font-medium font-roboto text-left'>
                            <Link>
                                {coursesHeading}
                            </Link>
                        </h1>
                    </div>
                    <ul className='flex'>
                        <li className='max-w-[51px] mr-[15px] float-left'>
                            <LazyLoadImage src={logoImg} alt="Logo" className="border border-solid border-[#002147] rounded-full" />
                        </li>
                        <li>
                            <p className='text-[18px] text-left'>skillupitacademy</p>
                        </li>
                    </ul>
                </div>
                <div className='clearfix'>
                    <div className='pr-[23px] pl-[25px] pt-[10px] pb-[15px]'>
                        <div className='flex flex-left'>
                            <div className='mr-[20px] text-[14px] bg-[#002147] text-[#fff] pt-[6px] px-[15px] pb-[5px] rounded-[50px]'>
                                <span>{core}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ backgroundColor: bgColor }} className='absolute top-0 h-full w-full z-0 py-[10px] px-[30px] rounded-[15px] max-xtrasmall:text-center lg:max-xl:text-center pt-16 opacity-0 group-hover:opacity-100  transition-opacity duration-500 ease-in-out custom-transform'>
                    <div>
                        <p className='bg-white bg-opacity-20 rounded-lg inline-block p-1.5 px-2.5 text-white text-left transition duration-500
                        text-[13px] sm:text-[14px] '
                        >
                            Any Stream
                        </p>
                        <h3 className='mt-[5%] font-extrabold text-[#fff] text-left text-[25px] sm:text-[30px] md:max-lg:text-[29px] md:max-lg:mr-[20px]'>{coursesHeading}</h3>
                        <p className='mt-[10%] text-[#fff] text-left border-b-[1px] border-b-solid border-white border-opacity-20  font-roboto leading-[30px] mb-[25%]
                        text-[16px] h-[150px] sm:text-[19px]'
                        >
                            {courseDis}
                        </p>
                        <div className='flex max-xtrasmall:flex-col max-xtrasmall:text-center justify-between lg:flex-col lg:text-center xl:flex-row xl:justify-between'>
                            <div className='w-1/2 mb-[10%] float-left max-xtrasmall:mx-auto max-xtrasmall:justify-center lg:w-auto lg:mx-auto flex lg:justify-center xl:justify-start xl:mx-0'>
                                <i className="fas fa-star pt-[5%] text-[#fdc800]" id="star1"></i>
                                <i className="fas fa-star pt-[5%] text-[#fdc800]" id="star2"></i>
                                <i className="fas fa-star pt-[5%] text-[#fdc800]" id="star3"></i>
                                <i className="fas fa-star pt-[5%] text-[#fdc800]" id="star4"></i>
                                <i className="fas fa-star pt-[5%] text-[#fdc800]" id="star5"></i>
                            </div>
                            <div>
                                <Link>
                                    <Cards3Btn text='Click To Enquire' />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
