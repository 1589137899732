import React, { useContext } from 'react'
import { OverlayContext } from '../context/OverlayContext'

export default function QuizFinish() {
    const { handleTryAgain } = useContext(OverlayContext);

    return (
        <div className=' w-full h-[100%] shadow-[0px_3px_12px] shadow-gray-300 rounded  p-4 mb-2'>
            <p>Check your score</p>
            <button onClick={handleTryAgain} className='bg-blue text-white px-4 py-2 rounded-md'>
                Play Again
            </button>
        </div>
    )
}
