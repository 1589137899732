import React, { useContext } from 'react'
import { ContactFormContext } from '../context/ContactFormContext'

export default function Button({ text }) {
    const { toggle } = useContext(ContactFormContext)

    return (
        <div className='py-[16px] px-[5px] uppercase text-[#fff] inline-block leading-normal text-[14px] font-bold bg-gradient-to-r from-[#133282] to-[#f54b8a] rounded-custom tracking-normal
            cursor-pointer transition-all duration-300 ease-in-out hover:rounded-customhover' onClick={() => toggle()}
        >
            <span>{text}</span>
        </div>
    )
}
