import course1 from '../img/courses/course25.jpg'
import course2 from '../img/courses/course26.jpg'
import course3 from '../img/courses/course3.jpg'
import course4 from '../img/courses/course27.jpg'
import course5 from '../img/courses/course28.jpg'
import course6 from '../img/courses/course29.jpg'

const FundamentalInfraStructureData = [
    {
        img: course1,
        coursesHeading: 'Cloud Computing - AZURE  ',
        core: 'International Certification*',
        h1: 'Cloud Computing - AZURE ',
        courseDis: 'Design and Deploy Solution On AZURE Platform',
        Duration: 'Duration : 3 months*',
        bgColor: '#9b59b6'
    },
    {
        img: course2,
        coursesHeading: 'Cloud Computing - AWS',
        core: 'International Certification*',
        h1: 'Cloud Computing - AWS',
        courseDis: 'Design and Deploy Solution On Amazon Platform',
        Duration: 'Duration : 4 months*',
        bgColor: '#2874a6'
    },
    {
        img: course3,
        coursesHeading: 'Cloud Computing - GCP',
        core: 'International Certification*',
        h1: 'Cloud Computing - Google',
        courseDis: 'Design and Deploy Solution On Google Cloud Platform',
        Duration: 'Duration : 3 months*',
        bgColor: '#fd29c5'
    },
    {
        img: course4,
        coursesHeading: 'Cloud Computing - Red Hat',
        core: 'Open Source Solution',
        h1: 'Cloud Computing - Red Hat',
        courseDis: 'Design and Deploy Solution On Red Hat Cloud Infrastructure',
        Duration: 'Duration : 3 months*',
        bgColor: '#196f3d'
    },
    {
        img: course5,
        coursesHeading: 'Infrasture Module',
        core: 'Fundamental Module',
        h1: 'Infrasture Management Curriculum',
        courseDis: 'Learn A+ , N+ , MCSE , MCSA , CCNA',
        Duration: 'Duration : 3 months*',
        bgColor: '#b7950b'
    },
    {
        img: course6,
        coursesHeading: 'Cloud - Kubernetes and Docker',
        core: 'Cutting Edge Solution',
        h1: 'Kubernetes and Docker: The Container Masterclass',
        courseDis: 'Containerized Web-applications Deployment On Cloud',
        Duration: 'Duration : 5 months*',
        bgColor: '#1abc9c'
    },
];

export default FundamentalInfraStructureData