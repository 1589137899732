import React from 'react'
import { Link } from 'react-router-dom'
// import custom1 from '../img/custom-3.png'
import Svg from '../img/custom-3.svg'

export default function Activity() {
    return (
        <div className='w-full lg:w-full px-2.5'>
            <div className='mb-5 rounded-[10px] bg-pink'>
                <div className='p-[30px]' style={{
                    backgroundImage: `url(${Svg})`,
                    backgroundSize: "auto 100%",
                    backgroundPosition: 'calc(100% + 0.5rem) bottom',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '10px'
                }}>
                    <div className='px-2.5 '>
                        <h4 className='text-white text-xl mb-2.5'>Test Prep</h4>
                        <p className='text-white text-base my-2.5 '>Grow marketing & sales <br /> through product.</p>
                        <Link to='/pages/quiz' className='rounded-md border inline-block border-transparent border-solid text-base py-2 px-4 bg-[#ffeae5] border-[#ffeae5] text-pink'>Read More</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
