import React from 'react'
import Attendance from '../components/Attendance'
import DashSideBar from '../components/DashSideBar'
import Greetings from '../components/Greetings'
import SubjectCompletion from '../components/SubjectCompletion'
// import LogoutButton from '../components/LogoutButton'
import Activity from '../components/Activity'
import BasedOn from '../components/BasedOn'
import Calender from '../components/Calender'

export default function Dashboard() {
    return (
        <>
            <div className='bg-[#fafbfd]'>
                <DashSideBar />
                <div className='md:ml-[260px]'>
                    <div className='container-full mx-auto'>
                        <section className='px-5 pt-2.5'>
                            <Greetings />
                            <div className='flex flex-wrap'>
                                <SubjectCompletion />
                                <Attendance />
                            </div>
                            <div className='flex flex-wrap flex-col lg:flex-row '>
                                <Calender />
                                <div className='flex flex-wrap flex-col justify-evenly lg:w-1/2 ' >
                                    <Activity />
                                    <BasedOn />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}
