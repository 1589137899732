import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import courseImg from '../img/courses/course20.jpg';
import courseImg1 from '../img/courses/course21.jpg';
import courseImg2 from '../img/courses/course22.jpg';
import courseImg3 from '../img/courses/course23.jpg';
import courseImg4 from '../img/courses/course24.jpg';
// import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Cards3 from "./Cards3";

const PreviousBtn = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
        <div className="arrow">
            <ArrowBackIosNewIcon />
        </div>
    </div>
);

const NextBtn = ({ className, onClick }) => (
    <div className={className} onClick={onClick}>
        <div className="arrow">
            <ArrowForwardIosIcon />
        </div>
    </div>
);

export default function RelatedCourses() {
    const fundamentalData = [
        {
            img: courseImg2,
            coursesHeading: 'MERN Stack Development',
            core: 'Open Source Solution',
            h1: 'MERN - JavaScript Framework',
            courseDis: 'Full Stack 3-Tier Application Development',
            Duration: 'Duration : 3 months*',
            bgColor: '#3478f6'
        },
        {
            img: courseImg,
            coursesHeading: 'Full Stack Development - .NET',
            core: 'International Certification*',
            h1: 'Full Stack Development - .NET',
            courseDis: 'Cross Platform Development Solution',
            Duration: 'Duration : 3 months*',
            bgColor: '#f5b016'
        },
        {
            img: courseImg1,
            coursesHeading: 'Full Stack Modules - Python',
            core: 'International Certification*',
            h1: 'Full Stack Modules - Python',
            courseDis: 'AI-Based App Development Solution',
            Duration: 'Duration : 3 months*',
            bgColor: '#f5b016'
        },
        {
            img: courseImg3,
            coursesHeading: 'MEAN Stack Development',
            core: 'Open Source Solution',
            h1: 'MEAN - JavaScript Framework',
            courseDis: 'Cloud Ready Development Solution',
            Duration: 'Duration : 3 months*',
            bgColor: '#f5b016'
        },
        {
            img: courseImg4,
            coursesHeading: 'Stack Development - AI',
            core: 'Cutting Edge Solution',
            h1: 'DS, ML, DL Based Development Solution',
            courseDis: 'Cloud Ready Development Solution',
            Duration: 'Duration : 5 months*',
            bgColor: '#fd29c5'
        },
    ];

    const settings = {
        dots: false,
        arrows: true,
        prevArrow: <PreviousBtn />,
        nextArrow: <NextBtn />,
        infinite: true,
        fade: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ]
    };

    const renderSlides = () => {
        const slides = [];
        slides.push(
            <div key="slide-1">
                <div className='pb-[70px] mt-20'>
                    <div className="container mx-auto">
                        <div className='mb-[50px] text-center'>
                            <div className='mb-[20px]'>
                                <h1 data-aos="fade-up" data-aos-duration="900" data-aos-once="false" data-aos-anchor-placement="bottom-bottom" className='font-proxima font-bold leading-[72px] tracking-tighter text-4xl'>
                                    <span className='text-pink'>Related</span>
                                    <span className='text-blue'> Modules</span>
                                </h1>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            {fundamentalData.slice(0, 3).map((data, idx) => (
                                <Cards3
                                    key={idx}
                                    img={data.img}
                                    coursesHeading={data.coursesHeading}
                                    core={data.core}
                                    h1={data.h1}
                                    courseDis={data.courseDis}
                                    Duration={data.Duration}
                                    bgColor={data.bgColor}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
        slides.push(
            <div key="slide-2">
                <div className='pb-[70px] mt-20'>
                    <div className="container mx-auto">
                        <div className='mb-[50px] text-center'>
                            <div className='mb-[20px]'>
                                <h1 data-aos="fade-up" data-aos-duration="900" data-aos-once="false" data-aos-anchor-placement="bottom-bottom" className='font-proxima font-bold leading-[72px] tracking-tighter text-4xl'>
                                    <span className='text-pink'>Related</span>
                                    <span className='text-blue'> Modules</span>
                                </h1>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            {fundamentalData.slice(3, 5).map((data, idx) => (
                                <Cards3
                                    key={idx}
                                    img={data.img}
                                    coursesHeading={data.coursesHeading}
                                    core={data.core}
                                    h1={data.h1}
                                    courseDis={data.courseDis}
                                    Duration={data.Duration}
                                    bgColor={data.bgColor}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
        return slides;
    };

    return (
        <div className="relative slider-area">
            <Slider {...settings}>
                {renderSlides()}
            </Slider>
        </div>
    );
}