import React from 'react'

export default function Button4({ text }) {
    return (
        <div className="group w-full block relative z-10 rounded-3xl overflow-hidden my-0 mx-auto border border-solid ">
            <div className="absolute -z-10 w-[300%] h-full bg-[#133282] bg-custom-gradient -left-full top-0 group-hover:left-0 transition-all duration-500"></div>
            <button className="font-medium text-xs text-white leading-5 uppercase flex justify-center items-center py-0 px-5 w-full h-[35px]">
                {text}
            </button>
        </div>
    )
}
