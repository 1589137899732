import React from 'react'

export default function Attendance() {
    return (
        <div className='w-full lg:w-1/2  px-2.5'>
            <div className='mb-5 w-full bg-white rounded-[10px]'>
                <div className='border-b border-b-solid text-blue block p-5 relative'>
                    <h4 className='inline-block m-0 font-normal text-lg'>
                        Attendance
                    </h4>
                </div>
                <div className='p-5'>
                    <div className='flex justify-center items-center'>
                        <div className="relative size-60 h-[300px]">
                            <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                {/* <!-- Background Circle --> */}
                                <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-pink opacity-80 dark:text-neutral-700"
                                    strokeWidth="2">
                                </circle>
                                {/* <!-- Progress Circle --> */}
                                <circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-blue dark:text-blue-500"
                                    strokeWidth="2" strokeDasharray="100" strokeDashoffset="25" strokeLinecap="round">
                                </circle>
                            </svg>

                            {/* <!-- Percentage Text --> */}
                            <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                <span className="text-center text-2xl font-bold text-blue ">75%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
