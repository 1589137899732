import course1 from '../img/courses/course19.jpg'
import course2 from '../img/courses/course20.jpg'
import course3 from '../img/courses/course21.jpg'
import course4 from '../img/courses/course22.jpg'
import course5 from '../img/courses/course23.jpg'
import course6 from '../img/courses/course24.jpg'

const FundamentalPlacementData = [
    {
        img: course1,
        coursesHeading: 'Full Stack Development - Java  ',
        core: 'International Certification*',
        h1: 'Full Stack Development - Java',
        courseDis: 'App And Web App Development Solution',
        Duration: 'Duration : 3 months*',
        bgColor: '#7665ff'
    },
    {
        img: course2,
        coursesHeading: 'Full Stack Development - .NET',
        core: 'International Certification*',
        h1: 'Full Stack Development - .NET',
        courseDis: 'Cross Platform Development Solution',
        Duration: 'Duration : 3 months*',
        bgColor: '#f5b016'
    },
    {
        img: course3,
        coursesHeading: 'Full Stack Modules - Python',
        core: 'International Certification*',
        h1: 'Full Stack Modules - Python',
        courseDis: 'AI-Based App Development Solution',
        Duration: 'Duration : 3 months*',
        bgColor: '#f20f10'
    },
    {
        img: course4,
        coursesHeading: 'MERN Stack Development',
        core: 'Open Source Solution',
        h1: 'MERN - JavaScript Framework',
        courseDis: 'Full Stack 3-Tier Application Development',
        Duration: 'Duration : 3 months*',
        bgColor: '#3478f6'
    },
    {
        img: course5,
         coursesHeading: 'MEAN Stack Development',
        core: 'Open Source Solution',
        h1: 'MEAN - JavaScript Framework',
        courseDis: 'Cloud Ready Development Solution',
        Duration: 'Duration : 3 months*',
        bgColor: '#0eb582'
    },
    {
        img: course6,
        coursesHeading: 'Stack Development - AI',
        core: 'Cutting Edge Solution',
        h1: 'Stack Development - AI',
        courseDis: 'DS, ML, DL Based Development Solution',
        Duration: 'Duration : 5 months*',
        bgColor: '#fd29c5'
    },
];

export default FundamentalPlacementData