import React from 'react'

export default function ContactAnchor() {
     
    return (
        <a href="tel:+917208581498" className="fixed bottom-[58%] -right-10 z-20 bg-prussianBlue text-white font-black py-[0.2rem] px-5 rounded-t-lg rotate-z-270 md:hidden">
            Call Now
        </a>
    )
}
