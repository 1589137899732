import img1 from "../img/slider/slider_bg_1.jpg";
import img2 from "../img/slider/slider_bg_2.jpg";
import img3 from '../img/slider/bg_3.jpg'
import businessImg from '../img/modules/business.jpg'
import placementImg from '../img/modules/placement.jpg'
import CloudComputingImg from '../img/modules/cloudcomputing.jpg'
import TestingImport from '../img/modules/testing.jpg'
import DataSciImg from '../img/slider/slider_bg_5.jpg'
import CyberSecureImg from '../img/slider/slider_bg_5.jpg'
import TrendingImg from '../img/slider/slider_bg_12.jpg'

const SlickSliderData = [
    { url: img1, Title: 'University, College, School Syllabus Education' },
    { url: img2, Title: 'Education Needs Complete Solution' }
];

export { SlickSliderData }

const SlickSliderData2 = [
    { url: img3, data: 'Fundametal Modules' },
];
export { SlickSliderData2 }

const SlickBusinessData = [
    { url: businessImg, data: 'Business Modules' },
];

export { SlickBusinessData }

const SlickWebDesignData = [
    { url: img1, data: 'Web Designing Modules' },
];

export { SlickWebDesignData }

const SlickPlacementData = [
    { url: placementImg, name: 'pic_1', data: 'Placement Modules' },
];

export { SlickPlacementData }

const SlickInfrastructureData = [
    { url: CloudComputingImg, data: 'Infrastructure Modules' },
];

export { SlickInfrastructureData }

const SlickTestingData = [
    { url: TestingImport, data: 'Testing Modules' },
];

export { SlickTestingData }

const SlickDataSciData = [
    { url: DataSciImg, data: 'Data Science & Visualization Modules' },
];

export { SlickDataSciData }

const SlickCyberData = [
    { url: CyberSecureImg, data: 'Cyber Security & Bitcoin Modules' },
];

export { SlickCyberData }

const SlickTrendingData = [
    { url:TrendingImg, name: 'pic_1', data: 'Trending Course Modules' },
];

export {SlickTrendingData}