import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import QuizInstruction from '../components/QuizInstruction'
import QuizNav from '../components/QuizNav'
import QuizQuestions from '../components/QuizQuestions'
import QuizScore from '../components/QuizScore'
import QuizTimer from '../components/QuizTimer'
import { OverlayContext } from '../context/OverlayContext'
import QuizFinish from '../components/QuizFinish'

export default function Quiz() {
    const { start, finish, score } = useContext(OverlayContext);
    return (
        <div className='relative'>
            <QuizNav />
            <div className='container mx-auto p-2'>
                <div className='flex flex-wrap'>
                    <div className='w-full md:w-1/4 px-4'>
                        <QuizTimer />
                        <QuizScore score={score} />
                    </div>
                    <div className='w-full md:w-3/4 px-4'>
                        {finish ?
                            <>
                                {start ?
                                    <QuizQuestions />
                                    :
                                    <QuizInstruction />
                                }
                            </>
                            :
                            <QuizFinish />
                        }
                    </div>
                </div>
            </div>
            <div className='text-pink py-2 my-2 text-center w-full block sm:text-lg font-semibold md:hidden'>
                <span className='mr-1'>
                    <i className="fa-solid fa-marker fa-lg"></i>
                </span>
                <Link>
                    View Instructions
                </Link>
            </div>
        </div>
    )
}
