import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components';
import img1 from '../img/bg_img.png'
import img2 from '../img/bg2_img.png'
import { Link } from 'react-router-dom';
import logo from '../img/logo/logo.png'
import { OverlayContext } from '../context/OverlayContext';

const shrink = keyframes`
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
`;

const CounterBgImg = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index:9;
  top:0;
  left:0;
  overflow-x: hidden;
  transition: width 1s;
  background-color: #fff;
  background-image: url(${img1}), url(${img2});
  background-repeat: no-repeat;
  background-position: left;
  animation: ${shrink} 3s infinite alternate;
`;
const pageNames = [
  'Fundamental Modules',
  'Business Modules',
  'Web Designing Modules',
  'Placement Modules',
  'Infrastructure Modules',
  'Testing Modules',
  'Data Science & Visualization',
  'Cyber Security & Bitcoin Modules',
  'Trending Modules'
]
export default function Overlay() {
  const { showOverLay, close } = useContext(OverlayContext);

  if (!showOverLay) return null

  return (
    <CounterBgImg>
      <Link className='absolute -top-[3px] right-11 text-[50px] text-blue' onClick={() => close()}>x</Link>
      <br />
      <div className='relative top-[10%] w-full text-center'>
        <div className='container mx-auto '>
          <div className='flex flex-col'>
            <img src={logo} alt='logo' className='w-2/5 mt-0 mx-auto' data-aos="fade-down" data-aos-duration="600" />
            <div className='rounded-[3px] mb-0 mt-0 p-30' data-aos="fade-up" data-aos-duration="600">
              {pageNames.map((name,id) => (
                <div className='border-md border-none rounded-none shadow-none mb-[30px] ml-5'>
                  <div className='rounded-[30px] p-0'>
                    <h4 className='text-24'>
                      <Link key={id} to={`/pages/${name}`}>
                        <div className=' text-[#fff] block leading-normal font-bold bg-gradient-to-r from-[#133282] to-[#f54b8a] rounded-custom tracking-normal
                        cursor-pointer transition-all duration-300 ease-in-out hover:rounded-customhover p-[2%] w-3/4 text-[22px] mx-auto max-md:text-[14px] ' onClick={() => close()}
                        >
                          <span >
                            {name}
                          </span>
                        </div>
                      </Link>
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CounterBgImg>
  )
}
