import React from 'react'
import Button2 from './Button2'

export default function Enroll({ data }) {
    return (
        <>
            {data.map((data, index) => (
                <div key={index} className={`bg-${data.bgColor}`}>
                    <div className='container mx-auto pt-[90px] pb-[65px]'>
                        <div className='text-center'>
                            <h1 data-aos="fade-up" data-aos-duration="900" className={`text-${[data.color]} max-sm:text-[30px] text-[48px]`}>
                                {data.h1}
                            </h1>
                            <p data-aos="fade-right" data-aos-duration="900" className='text-[14px] tracking-[1px] sm:text-[17px] sm:tracking-[3px] text-center'>
                                LEARN SOMETHING WHEREVER YOU ARE
                            </p>
                            <br />
                            <div data-aos="fade-left" data-aos-duration="900">
                                <Button2 text='join a course now' />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}