import React from 'react'

export default function QuizSingleQuest({ singleData, selectedOptions, handleSkipped }) {
    
    return (
        <>
            <div className=' w-full shadow-[0px_3px_12px] shadow-gray-300 rounded  p-4 mb-2'>
                <h1 className='mt-3 pl-4 mb-3 font-medium text-lg text-blue'>{singleData.id} : {singleData.question}</h1>
                <form>
                    <ul className='p-4' >
                        {singleData.options.map((option, index) => {
                            // console.log(option);
                            return (
                                <>
                                    <li key={index} className='pl-6 pb-4 '>
                                        <input
                                            type='radio'
                                            id={`option-${index}`}
                                            value={option}
                                            onChange={handleSkipped}
                                            className='accent-pink mr-2'
                                            checked={selectedOptions === option}
                                            name='options'
                                        />
                                        <label htmlFor={`option-${index}`} >{option}</label>
                                    </li>
                                </>
                            )
                        })}
                    </ul>
                </form>
            </div>
        </>
    )
}
