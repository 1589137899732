import course1 from '../img/courses/course31.jpg'
import course2 from '../img/courses/course32.jpg'
import course3 from '../img/courses/course33.jpg'
import course4 from '../img/courses/course34.jpg'
import course5 from '../img/courses/course35.jpg'

const FundamentalTestingData = [
    {
        img: course1,
        coursesHeading: 'Selenium WebDriver - Java',
        core: 'International Certification*',
        h1: 'Selenium WebDriver - Java',
        courseDis: 'Learn Selenium Web Driver, Selenium Grid and Docker.',
        Duration: 'Duration : 4 months*',
        bgColor: '#3498db'
    },
    {
        img: course2,
        coursesHeading: 'Appium -Mobile Testing',
        core: 'International Certification*',
        h1: 'Appium -Mobile Testing',
        courseDis: 'Master on Mobile Automation Testing (Android/IOS)',
        Duration: 'Duration : 4 months*',
        bgColor: '#f5b016'
    },
    {
        img: course3,
        coursesHeading: 'REST API - Testing',
        core: 'International Certification*',
        h1: 'REST API - Testing',
        courseDis: 'REST API Testing , Automation Testing with Java & TestNG',
        Duration: 'Duration : 3 months*',
        bgColor: '#f20f10'
    },
    {
        img: course4,
        coursesHeading: 'Selenium WebDriver - Python',
        core: 'International Certification*',
        h1: 'Selenium WebDriver - Python',
        courseDis: 'Learn Python Programming & Selenium Python Automation',
        Duration: 'Duration : 3 months*',
        bgColor: '#fd29c5'
    },
    {
        img: course5,
        coursesHeading: 'Software Testing - Cypress',
        core: 'Advanced Testing Module',
        h1: 'Software Testing - Cypress',
        courseDis: 'Automation Framework, Visual Testing, Cucumber, Docker, Jenkins',
        Duration: 'Duration : 3 months*',
        bgColor: '#27ae60'
    },
];

export default FundamentalTestingData