import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

export default function Calendar() {
    return (
        <div className='lg:w-1/2 px-2.5 my-5 text-blue'>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                events={[
                    { title: 'event 1', date: '2024-04-01' },
                    { title: 'event 2', date: '2024-04-02' }
                ]}
            />
        </div>
    )
}