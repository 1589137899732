import React from 'react'
import useReadingHook from '../hooks/useReadingHook'

export default function ScrollWrap() {
    const completion = useReadingHook();

    return (
        <div className='bg-prussianBlue h-[5px] fixed top-0 left-0 z-50 w-full'>
            <div style={{transform: `translateX(${completion -100}%)`}} className='h-[5px] bg-pink'></div>
        </div>
    )
}
 