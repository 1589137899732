import React from 'react'
import Cards1 from './Cards1'

export default function Courses() {
    const cardsData = [
        {
            header: "skillupitacademy",
            h1Span: "W",
            h1: "e at SkillUp IT Academy ensure that students are not only getting trained, educated in IT subjects or programming languages but also getting skilled so that they can explore knowledge and create new opportunities, innovation in this competitive and dynamic world."
        },
        {
            header: "skillupitacademy",
            h1Span: "W",
            h1: "e at SkillUp IT Academy work on to build platform where skill and ability can match up to build bright future of current and future generations. We believe in training where we train students to explore knowledge rather than settle on the existing skills they have."
        },
        {
            header: "skillupitacademy",
            h1Span: "T",
            h1: "hrough our extensive feedback-oriented training, practice, assignments, projects we ensure to achieve our company's objectives, values, mission and belief towards kids, students, professionals who aim IT as their dream career and prospects."
        }
    ]
    return (
        <div className='mt-20 pb-[70px]'>
            <div className="container mx-auto">
                <div className='mb-[50px] text-center'>
                    <div className='mb-[20px]'>
                        <h1 data-aos="fade-up" data-aos-duration="900" data-aos-once="false" className='font-proxima font-bold leading-[72px] tracking-tighter text-4xl'>
                            <span className='text-pink'>About</span>
                            <span className='text-blue'> US</span>
                        </h1>
                    </div>
                </div>
                <div className='flex flex-wrap justify-center'>
                    {cardsData.map((cardData, id) => {
                        return <Cards1 key={id} header={cardData.header} h1Span={cardData.h1Span} h1={cardData.h1} />
                    })}
                </div>
            </div>
        </div>
    )
}
