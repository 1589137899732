import React from 'react'
import { Link } from 'react-router-dom'

export default function LoginAnchor() {
    return (
        <Link to='/pages/form' className="fixed bottom-[42%] -right-[1.6rem] z-20 bg-prussianBlue text-white font-black py-[0.2rem] px-5 rounded-t-lg rotate-z-270 md:hidden">
           Login
        </Link>
    )
}
