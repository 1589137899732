import React, { useContext } from 'react'
import { OverlayContext } from '../context/OverlayContext';

export default function QuizTimer() {
    const { start, minutes, seconds } = useContext(OverlayContext)

    return (
        <div className='w-full shadow-[0px_3px_12px] shadow-gray-300 rounded text-center p-4 text-4xl 
            font-semibold mb-2'
        >
            {start ?
                <div>
                    <span className='text-blue'>{minutes < 10 ? '0' : ''}{minutes}:</span><span className='text-pink'>{seconds < 10 ? '0' : ''}{seconds}</span>
                </div>
                :
                <div>
                    <span className='text-blue'>00:</span><span className='text-pink'>00</span>
                </div>
            }
        </div>
    )
}
