import React from 'react'

export default function Button({ text }) {
    return (
        <button className='uppercase text-[#fff] inline-block leading-normal font-bold bg-gradient-to-r from-[#133282] to-[#f54b8a] rounded-custom tracking-normal
            cursor-pointer transition-all duration-300 ease-in-out hover:rounded-customhover py-[18px] px-[20px] text-[14px] sm:py-[16px] sm:px-[35px] ' type='submit'
        >
            <span>{text}</span>
        </button>
    )
}
