import React from 'react'
// import custom1 from '../img/custom-3.png'
import Svg from '../img/custom-4.svg'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Activity() {
    return (
        <div className='w-full lg:w-full px-2.5'>
            <div className='mb-5 rounded-[10px] bg-blue'>
                <div className='p-[30px]' style={{
                    backgroundImage: `url(${Svg})`,
                    backgroundSize: "auto 100%",
                    backgroundPosition: 'calc(100% + 0.5rem) bottom',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className='px-2.5 '>
                        <h4 className='text-white text-xl mb-2.5'>Based On</h4>
                        <div className='mt-1 mb-2 text-base'>
                            <div className='flex mb-2 text-base items-baseline text-white'>
                                <span className='mt-[5px] mr-[10px]'>
                                    <ArrowForwardIcon style={{ fontSize: '16px' }} />
                                </span>
                                <span className='text-white'>Activities</span>
                            </div>
                            <div className='flex mb-2 text-base items-baseline text-white'>
                                <span className='mt-[5px] mr-[10px]'>
                                    <ArrowForwardIcon style={{ fontSize: '16px' }} />
                                </span>
                                <span className='text-white'>Sales</span>
                            </div>
                            <div className='flex mb-2 text-base items-baseline text-white'>
                                <span className='mt-[5px] mr-[10px]'>
                                    <ArrowForwardIcon style={{ fontSize: '16px' }} />
                                </span>
                                <span className='text-white'>Releases</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
