import course1 from '../img/courses/course17.jpg'
import course2 from '../img/courses/course18.jpg'
import course3 from '../img/courses/course19.jpg'  

 const DataSciVizData2 = [
    {
        img: course1,
        coursesHeading: 'Data Science - I',
        core: 'International Certification*',
        h1: 'Data Science - I',
        courseDis: 'Learn Data Science. Data Visualization. -Data Science - I',
        Duration: 'Duration : 4 months*',
        bgColor: '#f20f10'
    },
    {
        img: course2,
        coursesHeading: 'Data Science - II',
        core: 'International Certification*',
        h1: 'Data Science - II',
        courseDis: 'Learn Data Science. Data Visualization. -Data Science - II',
        Duration: 'Duration : 4 months*',
        bgColor: '#b7950b'
    },
    {
        img: course3,
        coursesHeading: 'SQL',
        core: 'International Certification*',
        h1: 'SQL',
        courseDis: 'Learn Data Science. Data Visualization. - SQL',
        Duration: 'Duration : 3 months*',
        bgColor: '#196f3d'
    },
];

export default DataSciVizData2 