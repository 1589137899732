import React from 'react'
import img from '../img/avatar/avatar-1.png'
import img1 from '../img/avatar/avatar-2.png'

export default function SubjectCompletion() {
    const dataArr = [
        { name: 'C++', img: img1, bgColor: '#f54a8d', percentage: '50', text: '50' },
        { name: 'HTML/CSS', img: img, bgColor: '#f54a8d', percentage: '90', text: '90' },
        { name: 'JAVASCRIPT', img: img, bgColor: '#f54a8d', percentage: '80', text: '80' },
        { name: 'REACT', img: img1, bgColor: '#f54a8d', percentage: '60', text: '60' },
    ]
    return (
        <div className='w-full lg:w-1/2 px-2.5'>
            <div className='mb-5 w-full  bg-white rounded-[10px]'>
                <div className='border-b border-b-solid text-blue block p-5 relative'>
                    <h4 className='inline-block m-0 font-normal text-lg'>
                        Students Progress
                    </h4>
                    {/* <ul className='absolute top-7 right-7'>
                        <li>
                            <button className='text-xs inline-block opacity-80 bg-[#0052cc] border-[#0052cc] text-white py-1 px-3 rounded-lg' data-bs-toggle="dropdown" href="#">View List</button>
                        </li>
                    </ul> */}
                </div>
                <div className='p-5'>
                    <div className='flex flex-col flex-wrap mb-[30px]'>
                        {dataArr.map((item) => {
                            return (
                                <div className='flex items-center font-medium relative mb-[30px] '>
                                    <>
                                        <div className='w-[50px] mr-[15px] table'>
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div>
                                            <a className='mb-[5px] block text-blue text-black cursor-pointer'>{item.name}</a>
                                            <div className="line stripesLoader w-[140px] sm:w-[200px] " style={{ backgroundColor: `${item.bgColor}`, backgroundPosition: `${item.percentage}%` }}></div>
                                        </div>
                                        <div className='absolute top-7 right-2'>
                                            <button className='text-xs inline-block  bg-[#0052cc] border-[#0052cc] text-white py-1 px-3 rounded-xl' data-bs-toggle="dropdown" href="#">{item.text}%</button>
                                        </div>
                                    </>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
