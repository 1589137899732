import fundamentalImg from '../img/modules/fundamental.jpg'
import webDesign from '../img/modules/webdesign.jpg'
import bussiness from '../img/modules/business.jpg'
import course1 from '../img/courses/course1.jpg'
import course2 from '../img/courses/course2.jpg'
import course3 from '../img/courses/course3.jpg'
import course4 from '../img/courses/course4.jpg'
import course5 from '../img/courses/course5.jpg'
import course6 from '../img/courses/course6.jpg'

const FundamentalCoursesData = [
    {
        img: fundamentalImg,
        name: 'Fundamental Modules',
        coursesHeading: 'Fundamental Module',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#7665ff'
    },
    {
        img: webDesign,
        name: 'Web Designing Modules',
        coursesHeading: 'WebDesign Module',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#f5b016'
    },
    {
        img: bussiness,
        name: 'Business Modules',
        coursesHeading: 'Business Module',
        core: 'Business Module',
        courseDis: 'Excel at doing what your passion is and only focus on perfecting it.',
        bgColor: '#f20f10'
    },
]

export default FundamentalCoursesData

const FundamentalCoursesPageData = [
    {
        img: course1,
        coursesHeading: 'Programming in C',
        core: 'Core Programming',
        courseDis: 'C programming is a general-purpose, procedural, imperative computer programming language...',
        bgColor: '#7665ff'
    },
    {
        img: course2,
        coursesHeading: 'Programming in C++',
        core: 'Core Programming',
        courseDis: 'C++ is a cross-platform language that can be used to create high-performance applications...',
        bgColor: '#f5b016'
    },
    {
        img: course3,
        coursesHeading: 'Basic Python',
        core: 'Core Programming',
        courseDis: 'Python is an interpreted, high-level, general-purpose programming language...',
        bgColor: '#f20f10'
    },
    {
        img: course4,
        coursesHeading: 'Website Design',
        core: 'Core Programming',
        courseDis: 'Web design is the process of planning, conceptualizing, and arranging content...',
        bgColor: '#3478f6'
    },
    {
        img: course5,
        coursesHeading: 'Programming in C#',
        core: 'Advanced Programming',
        courseDis: 'C# (C-Sharp) is a programming language developed by Microsoft that runs on...',
        bgColor: '#0eb582'
    },
    {
        img: course6,
        coursesHeading: 'SQl',
        core: 'Database Module',
        courseDis: 'SQL stands for Structured Query Language. SQL is used to communicate with a...',
        bgColor: '#fd29c5'
    },
]

export {FundamentalCoursesPageData}

const FundamentalBusinessData = [
    {
        img: course1,
        coursesHeading: 'Advance Excel',
        p1Tag: 'Any Stream',
        core: 'Information Management System',
        courseDis: 'Excel has its immense purposeful applications. Through his post we look at the most...',
        bgColor: '#9b59b6'
    },
    {
        img: course2,
        p1Tag: 'Any Commerce Stream',
        coursesHeading: 'Tally',
        core: 'Accounting Module',
        courseDis: 'Tally is an on-premise accounting solution that caters primarily to small and midsize... ',
        bgColor: '#2874a6'
    },
    {
        img: course3,
        p1Tag: 'Any Stream',
        coursesHeading: 'Microsoft Office',
        core: 'Office Productivity Module',
        courseDis: 'Practical Microsoft Office courses on Excel, Word, PowerPoint, Outlook, Project ...',
        bgColor: '#fd29c5'
    },
    {
        img: course4,
        p1Tag: 'Any Stream',
        coursesHeading: 'Sql',
        core: 'Information Management System',
        courseDis: 'SQL stands for Structured Query Language. SQL is used to communicate with a...',
        bgColor: '#196f3d'
    },
    {
        img: course5,
        p1Tag: 'Any Stream',
        coursesHeading: 'Tableau',
        core: 'Analytics Tool',
        courseDis: 'Tableau is a powerful and fastest growing data visualization tool used in the Business...',
        bgColor: '#b7950b'
    },
    {
        img: course6,
        p1Tag: 'Any Stream',
        coursesHeading: 'Digital Marketing',
        core: 'Business Marketing',
        courseDis: 'Digital marketing is the use of the Internet to reach consumers. Digital marketing is a...',
        bgColor: '#1abc9c'
    },
]

export {FundamentalBusinessData}

