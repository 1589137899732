import React from 'react'
import Navbar from '../components/Navbar'
import ScrollWrap from '../components/ScrollWrap'
import MainWrap from '../components/MainWrap'
import ContactFormWrap from '../components/ContactFormWrap'
import Overlay from '../components/Overlay'
import SlickSlider2 from '../components/SlickSlider2'
import { SlickWebDesignData } from '../data/SlickSliderData'
import FundamentalCourses2 from '../components/FundamentalCourses2'
import FundamentalWebDesignData from '../data/FundamentalWebDesignData'
import CounterArea from '../components/CounterArea'
import { CounterData2 } from '../data/CounterData'
import Enroll from '../components/Enroll'
import { EnrollData4 } from '../data/EnrollData'
import RelatedCourses from '../components/RelatedCourses'
import Footer from '../components/Footer'
import ContactAnchor from '../components/ContactAnchor'
import LoginAnchor from '../components/LoginAnchor'

export default function WebDesignModule() {
    return (
        <>
            <ContactAnchor />
            <LoginAnchor />
            <ScrollWrap />
            <Navbar />
            <MainWrap />
            <ContactFormWrap/>
            <Overlay />
            <SlickSlider2 data={SlickWebDesignData}/>
            <FundamentalCourses2 data={FundamentalWebDesignData} />
            <CounterArea data={CounterData2} />
            <Enroll data={EnrollData4}/>
            <RelatedCourses />
            <Footer />
        </>
    )
}
