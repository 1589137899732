import React, { useEffect, useRef, useState } from 'react'
import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'

export default function Navbar() {
    const [isFixed, setIsFixed] = useState(false);
    const headRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 300) {
                setIsFixed(true);
            } else {
                setIsFixed(false)
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);
    return (
        <header id='Home'>
            <div className='bg-[#fff]'>
                <HeaderTop />
                <div className={`${isFixed ? 'sticky ' : ''} `} ref={headRef} style={{ transition: '0.6s' }}>
                    <HeaderBottom />
                </div>
            </div>
        </header>
    )
}
