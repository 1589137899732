import React, { useContext } from 'react'
import { OverlayContext } from '../context/OverlayContext'

export default function QuizInstruction() {
    const {start, handleStart} = useContext(OverlayContext);
    const rules = [
        { rule: 'Total number of questions: 20' },
        { rule: 'You have 10 minutes to complete the quiz.' },
        { rule: 'A countdown timer will be displayed.' },
        { rule: 'Select the correct answer for each question.' },
        { rule: 'You can use number button for checking questions.' },
        { rule: 'Submit your answers before the timer runs out.' },
        { rule: 'Your score will be displayed at the end of the quiz.' },
    ]
    return (
        <div className=' w-full shadow-[0px_3px_12px] shadow-gray-300 rounded  p-4 mb-2'>
            <h2 className='text-4xl text-center'>
                <span className='text-pink font-semibold'>Quiz</span><span className='text-blue font-semibold'> Instruction</span>
            </h2>
            <div className='w-full font-medium'>
                <ul className='p-5' >
                    {rules.map((item, id) => {
                        return (
                            <li key={id} className='pb-3 pl-8 relative'>
                                <span className='absolute left-0'>{id + 1}.</span>
                                {item.rule}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='text-center'>
                <button onClick={handleStart} className='bg-blue text-white px-4 py-2 rounded-md'>
                    Start Your Test
                </button>
            </div>
        </div>
    )
}
