import React from 'react'
import ScrollWrap from '../components/ScrollWrap'
import Navbar from '../components/Navbar'
import MainWrap from '../components/MainWrap'
import ContactFormWrap from '../components/ContactFormWrap'
import Overlay from '../components/Overlay'
import SlickSlider2 from '../components/SlickSlider2'
import { SlickInfrastructureData } from '../data/SlickSliderData'
import FundamentalCourses2 from '../components/FundamentalCourses2'
import CounterArea from '../components/CounterArea'
import { CounterData2 } from '../data/CounterData'
import Enroll from '../components/Enroll'
import { EnrollData5 } from '../data/EnrollData'
import RelatedCourses from '../components/RelatedCourses'
import Footer from '../components/Footer'
import FundamentalInfraStructureData from '../data/FundamentalInfraStructureData'
import ContactAnchor from '../components/ContactAnchor'
import LoginAnchor from '../components/LoginAnchor'

export default function InfrastructureModule() {
    return (
        <>
            <ContactAnchor />
            <LoginAnchor />
            <ScrollWrap />
            <Navbar />
            <MainWrap />
            <ContactFormWrap />
            <Overlay/>
            <SlickSlider2 data={SlickInfrastructureData}/>
            <FundamentalCourses2 data={FundamentalInfraStructureData} />
            <CounterArea data={CounterData2} />
            <Enroll data={EnrollData5}/>
            <RelatedCourses />
            <Footer />
        </>
    )
}
