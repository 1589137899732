import course1 from '../img/courses/course31.jpg'
import course2 from '../img/courses/course32.jpg'
import course3 from '../img/courses/course33.jpg'  

 const CyberSecureModData = [
    {
        img: course1,
        coursesHeading: 'Cyber Security',
        core: 'International Certification*',
        h1: 'Cyber Security',
        courseDis: 'Become a Cyber Security Specialist',
        Duration: 'Duration : 4 months*',
        bgColor: '#3498db'
    },
    {
        img: course2,
        coursesHeading: 'Ethical Hacking',
        core: 'International Certification*',
        h1: 'Ethical Hacking',
        courseDis: 'Learn how to become an elite ethical hacker',
        Duration: 'Duration : 4 months*',
        bgColor: '#f5b016'
    },
    {
        img: course3,
        coursesHeading: 'Blockchain. Bitocin. Cryptocurrency',
        core: 'International Certification*',
        h1: 'Blockchain. Bitocin. Cryptocurrency',
        courseDis: 'Learn Blockchain. Bitocin. Cryptocurrency Trading',
        Duration: 'Duration : 3 months*',
        bgColor: '#f20f10'
    },
];

export default CyberSecureModData