import React, { createContext, useState } from 'react'

export const ContactFormContext = createContext()

export function ContactFormProvider({ children }) {
    const [showModal, setShowModal] = useState(false)

    const toggle = () => {
        setShowModal(true)
    }

    const close = () => {
        setShowModal(false);
    }
    return (
        < ContactFormContext.Provider
            value={{ showModal, toggle, close }}
        >
            {children}
        </ ContactFormContext.Provider>
    )
}
