import counter from "../img/counter/counter_icon1.png"
import counter2 from "../img/counter/counter_icon2.png"
import counter3 from "../img/counter/counter_icon3.png"
import counter4 from "../img/counter/counter_icon4.png"

const CounterData = [
    {
        img: counter,
        span: '100+ Topics',
        h3: 'LEARN ANYTHING',
        animate: 'fade-right'
    },
    {
        img: counter2,
        span: '1900+ Students',
        h3: 'FUTURE GENIUS',
        animate: 'fade-up'
    },
    {
        img: counter3,
        span: '15900 Tests Taken',
        h3: 'THATS A LOT',
        animate: 'fade-down'
    },
    {
        img: counter4,
        span: '10+ Instructors',
        h3: 'ALL TRAINED PROFESSIONALS',
        animate: 'fade-left'
    },
]

export {CounterData}

const CounterData2 = [
    {
        img: counter,
        span: '216.67%',
        h3: 'MAX SALARY HIKE',
        animate: 'fade-right'
    },
    {
        img: counter2,
        span: '91.62%',
        h3: 'AVERAGE SALARY HIKE',
        animate: 'fade-up'
    },
    {
        img: counter3,
        span: '96%',
        h3: 'PLACEMENT SUCCESS RATE',
        animate: 'fade-down'
    },
    {
        img: counter3,
        span: '45',
        h3: 'NO. OF COMPANIES',
        animate: 'fade-down'
    },
]

export {CounterData2}