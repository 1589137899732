const EnrollData = [
    {
        h1: 'Start a Journey. Enroll Now.',
        bgColor: 'prussianBlue',
        color: 'white'
    }
]

export {EnrollData}

const EnrollData2 = [
    {
        h1: 'Learning Today. Leading Tomorrow. Enroll Now.',
        bgColor: 'prussianBlue',
        color: 'white'
    }
]

export {EnrollData2}

const EnrollData3 = [
    {
        h1: 'Discover Your Future With US. Enroll Now.',
        bgColor: 'white',
        color: 'prussianBlue'
    }
]

export {EnrollData3}

const EnrollData4 = [
    {
        h1: 'Creative Thinking & Innovation. Enroll Now.',
        bgColor: 'prussianBlue',
        color: 'white'
    }
]

export {EnrollData4}

const EnrollData5 = [
    {
        h1: 'Your bright future is our mission. Enroll Now.',
        bgColor: 'prussianBlue',
        color: 'white'
    }
]
export {EnrollData5}