import React from 'react'
import { Link } from 'react-router-dom'

const courses = [
    'Full Stack Development',
    'Data Science',
    'Web APP',
    'APP Development',
    'Deep Learning',
    'Python'
]

const quickLinks = [
    'Placement Modules',
    'Business Modules',
    'Web Designing Modules',
    'Cyber Security Modules',
    'Bitcoin Modules',
    'Cryptocurrency Modules',
    'Ethical Hacking Modules'
]

const contacts = [
    { icon: 'fas fa-phone', description: '7208581498' },
    { icon: 'ti ti-email', description: 'info@skillupitacademy.com' },
    { icon: 'ti ti-location-pin', description: '807 Time Chambers S.V. ROAD Andheri (W)' },
    { icon: 'ti ti-location-pin', description: 'B-205 2nd Flr. Neelyog Square Ghatkopar (E)' }
]

const Section = ({ title, items, renderItem }) => (
    <div className='mb-[30px] w-full md:w-[370px] lg:w-[300px] xl:w-[370px]'>
        <h1 className='text-2xl text-yellow-400 font-bold leading-none mr-0 mb-8'>
            {title}
        </h1>
        <div>
            <ul data-aos="fade-up" data-aos-duration="900"className='custom-fade-up'>{items.map(renderItem)}</ul>
        </div>
    </div>
)

const Footer = () => (
    <footer>
        <div className='bg-prussianBlue pt-[150px]'>
            <div className='container mx-auto'>
                <div className='flex flex-wrap justify-evenly md:justify-start lg:justify-center xl:justify-evenly border-b-[1px] border-b-white border-opacity-15 pb-[35px] max-sm:pl-[10px]'>
                    <Section
                        title="Popular Courses"
                        items={courses}
                        renderItem={(course, index) => (
                            <li key={index} className=' leading-none'>
                                <Link className='text-base text-white text-opacity-65 p-0 block leading-none mb-[14px] hover:text-yellow-400 hover:opacity-100'>
                                    {course}
                                </Link>
                            </li>
                        )}
                    />
                    <Section
                        title="Quick Links"
                        items={quickLinks}
                        renderItem={(link, index) => (
                            <li key={index} className='leading-none'>
                                <Link className='text-base text-white text-opacity-65 p-0 block leading-none mb-[14px] hover:text-yellow-400 hover:opacity-100'>
                                    {link}
                                </Link>
                            </li>
                        )}
                    />
                    <Section
                        title="Contact Us"
                        items={contacts}
                        renderItem={(contact, index) => (
                            <div key={index} className='mb-[10px]'>
                                <span className='text-sm text-yellow-400'>
                                    <i className={contact.icon}></i>
                                </span>
                                <span className="text-base text-white opacity-65 ml-2">{contact.description}</span>
                            </div>
                        )}
                    />
                </div>
                <div className='pt-[25px] pb-[25px]'>
                    <div className='container mx-auto'>
                        <div className='text-center'>
                            <span className='text-base text-gray-500'>
                                SkillUp IT Academy All Rights Reserved
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
