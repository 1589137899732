import course1 from '../img/courses/course31.jpg'
import course2 from '../img/courses/course32.jpg'
import course3 from '../img/courses/course33.jpg'  

 const DataSciVizData = [
    {
        img: course1,
        coursesHeading: 'Python',
        core: 'International Certification*',
        h1: 'Python',
        courseDis: 'Learn Data Science. Data Visualization. - Python',
        Duration: 'Duration : 4 months*',
        bgColor: '#3498db'
    },
    {
        img: course2,
        coursesHeading: 'R Language',
        core: 'International Certification*',
        h1: 'R Language',
        courseDis: 'Learn Data Science. Data Visualization. - R',
        Duration: 'Duration : 4 months*',
        bgColor: '#9b59b6'
    },
    {
        img: course3,
        coursesHeading: 'Tableau',
        core: 'International Certification*',
        h1: 'Tableau',
        courseDis: 'Learn Data Science. Data Visualization. - Tableau',
        Duration: 'Duration : 3 months*',
        bgColor: '#196f3d'
    },
];

export default DataSciVizData 