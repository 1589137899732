import React, { createContext, useEffect, useState } from 'react'
import quizdata from '../data/Quiz.json';

export const OverlayContext = createContext()

export function OverlayProvider({ children }) {
    const arr = quizdata.map((data) => {
        return data;
    })
    const [data, setData] = useState(arr);
    const [showOverLay, setShowOverlay] = useState(false)
    const [start, setStart] = useState(false);
    const [finish, setFinish] = useState(true);
    const [timer, setTimer] = useState(600);
    const [timeInterval, setTimeInterval] = useState(null);
    const scoreDataArr = [
        { bgColor: 'rgb(0, 148, 32)', text: 'Total Correct' },
        { bgColor: 'rgb(201, 0, 0)', text: 'Total Wrong' },
        { bgColor: 'rgb(236, 152, 26)', text: 'Total Skip', value: 0 },
        { bgColor: '#133382', text: 'Bookmark', value: 0 },
    ]
    const [score, setScore] = useState(scoreDataArr);
    const toggle = () => {
        setShowOverlay(true)
    }
    const close = () => {
        setShowOverlay(false);
    }
    const handleStart = () => {
        setStart(!start);
        setTimeInterval(setInterval(() => {
            setTimer((prev) => prev - 1);
        }, 1000));
    }
    useEffect(() => {
        if (timer === 0) {
            clearInterval(timeInterval);
            setFinish(!finish);
            handleSubmit()
            setData(arr);
        }
    }, [timer]);

    const pauseTimer = () => {
        clearInterval(timeInterval);
    }
    const handleFinish = () => {
        setFinish(!finish);
        pauseTimer()
        
    }
    const handleTryAgain = () => {
        const reset = score.map((item) => {
            if (item.text === 'Total Skip') {
                return { ...item, value: 0 }
            }
            if (item.text === 'Bookmark') {
                return { ...item, value: 0 }
            }
            return item
        })
        setScore(reset)
        setStart(!start);
        setFinish(!finish);
        setTimer(600)
    }
    const handleScoreCount = (data) => {
        const count = data.filter((item) => {
            return item.bookmark === true;
        })
        const countSkip = data.filter((item) => {
            return item.isChecked !== true;
        })
        const updatedScore = score.map((item) => {
            if (item.text === 'Bookmark') {
                const newBookCount = { ...item, value: count.length }
                return newBookCount
            }
            if (item.text === 'Total Skip') {
                const newSkipCount = { ...item, value: countSkip.length }
                return newSkipCount
            }
            return item
        })
        setScore(updatedScore)
    }
    const handleSubmit = () => {
        handleScoreCount(data);
        handleFinish()
        setData(arr);
    }
    const minutes = Math.floor((timer % 3600) / 60);
    const seconds = timer % 60;
    return (
        <OverlayContext.Provider
            value={{
                showOverLay, start, minutes, seconds, finish, score, data,
                setData, setScore, handleStart, toggle, close, handleFinish, handleTryAgain, handleSubmit,handleScoreCount
            }}
        >
            {children}
        </OverlayContext.Provider>
    )
}
