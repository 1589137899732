import React from 'react'
import img1 from "../img/counter/bg_img.png"
import img2 from "../img/bg3_img.png"
import styled, { keyframes } from 'styled-components';

const shrink = keyframes`
  0% {
    background-size: 110%;
  }
  100% {
    background-size: 100%;
  }
`;

const CounterBgImg = styled.div`
background-color: #fafafa;
 background-image: url(${img1}), url(${img2});
 background-repeat: no-repeat;
 background-position: left;
 animation: ${shrink} 2s infinite alternate;
`;

export default function CounterArea({ data }) {
    return (
        <CounterBgImg>
            <div className='container mx-auto pt-[90px] pb-[65px]'>
                <div className='flex flex-wrap justify-evenly '>
                    {data.map((data, id) => (
                        <div key={id} data-aos={data.animate} data-aos-duration="900" >
                            <div className='group pt-[70px] px-[20px] pb-[60px] w-[300px] sm:w-[200px] md:w-[300px] lg:w-[200px] lg:h-[250px] xl:w-[250px] rounded-[50%] text-center  hover:shadow-custom hover:bg-pink hover:text-white transition duration-500'>
                                <img src={data.img} alt='counter' className='mb-[24px] w-[25%] mx-auto' />
                                <span className='block text-xl font-bold tracking-tighter word-spacing-widest'>
                                    {data.span}
                                </span>
                                <h3 className='group-hover:text-white m-0 text-[12px] block leading-none font-thin font-sans text-[#8a8a8a] mt-[9px] tracking-wider  transition duration-500 '>
                                    {data.h3}
                                </h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CounterBgImg>
    )
}
