import React from 'react'
import Cards2 from './Cards2'

export default function FundamentalCourses({ data }) {
    let justify = (data.length % 2 === 0) ? ' lg:justify-center' : '';
    return (
        <div className='pb-[70px] mt-20'>
            <div className="container mx-auto">
                <div className='mb-[50px] text-center'>
                    <div className='mb-[20px]'>
                        <h1 data-aos="fade-up" data-aos-duration="900" data-aos-once="false" data-aos-anchor-placement="bottom-bottom" className='font-proxima font-bold leading-[72px] tracking-tighter text-4xl'>
                            <span className='text-pink'>Our</span>
                            <span className='text-blue'> Modules</span>
                        </h1>
                    </div>
                </div>
                <div className={`flex flex-wrap justify-center md:justify-start ${justify}`}>
                    {data.map((data, id) => {
                        return (
                            <Cards2
                                key={id}
                                img={data.img}
                                name={data.name}
                                coursesHeading={data.coursesHeading}
                                core={data.core}
                                courseDis={data.courseDis}
                                bgColor={data.bgColor}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
