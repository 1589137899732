import placement from '../img/modules/placement.jpg'
import cloudcomputing from '../img/modules/cloudcomputing.jpg'
import webDesign from '../img/modules/webdesign.jpg'

const FundamentalCoursesData3 = [
    {
        img: placement,
        name: 'Data Science & Visualization',
        coursesHeading: 'Data Science & Visualization',
        core: 'Core Programming',
        courseDis: ' Success is the natural consequence of consistently applying the basic fundamentals.',
        bgColor: '#fd29c5'
    },
    {
        img: cloudcomputing,
        name: 'Cyber Security & Bitcoin Modules',
        coursesHeading: 'Cyber Security & Bitcoin',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#0eb582'
    },
    {
        img: webDesign,
        name: 'Cyber Security & Bitcoin Modules',
        coursesHeading: 'Trending Course',
        core: 'Core Designing',
        courseDis: 'What separates design from art is that design is meant to be... functional.',
        bgColor: '#7665ff'
    },
]

export default FundamentalCoursesData3